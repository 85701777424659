import React,  { Component } from 'react'
import {Header, Grid} from 'semantic-ui-react'
import EventContext from "../../../context/event-context";
import UserResponse from "../UserResponse/UserResponse";
import DateDisplay from "../../DateDisplay/DateDisplay";
import moment from 'moment';

class Banner extends Component {
    constructor(props){
        super(props);

        this.buttonConfirm = React.createRef();
        this.buttonDecline = React.createRef();
    }

    componentDidMount() {
    }

    static contextType = EventContext;
    render(){
        let questionHtml = "Are you going?";
        let duration = null;
        if(this.context.eventDate && this.context.rsvpDate){

            const startDate = moment(this.context.rsvpDate,"DD MMM YYYY 'at' LT");
            const timeEnd = moment(this.context.eventDate,"DD MMM YYYY 'at' LT");
            const diff = timeEnd.diff(startDate);

            let diffDuration = moment.duration(diff);
            duration = (diffDuration.years() ? diffDuration.years() +" year " : "") +
                (diffDuration.months() ? diffDuration.months() +" month " : "") + 
                (diffDuration.days() ? diffDuration.days() +" days to respond " : "");
        }
        if (this.context.attendanceResponse !== null){
            questionHtml = "Thanks for your answer";
            duration = null;
        }
        return (
            <EventContext.Consumer>
                {context => (
                    <Grid stackable columns={2} className='banner'>
                        <Grid.Column width={10}>
                            <Header as='h2' className='banner__date'>
                                <DateDisplay date={context.eventDate} includeTime={true}/>
                            </Header>
                            <Header as='h1'  className='banner__title'>
                                <Header.Content>
                                    {context.title}
                                    <Header.Subheader className='banner__subtitle'>{context.subtitle}</Header.Subheader>
                                </Header.Content>
                            </Header>
                        </Grid.Column>
                        <Grid.Column width={6}>
                            <Grid>
                                <Grid.Column floated='left' width={8}>
                                    <Header as='h4'>{questionHtml}</Header>
                                </Grid.Column>
                                <Grid.Column floated='right' width={8}>
                                    {duration ?
                                        <Header as='h4' floated='right'><i>{duration}</i></Header>
                                        : ""
                                    }
                                </Grid.Column>
                            </Grid>

                                <UserResponse/>
                        </Grid.Column>
                    </Grid>
                )}

            </EventContext.Consumer>
        )
    }
}

export default Banner;