import React from 'react';
import { List } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';

const SocialSharing = (props) => {

    let location = props.location;
    return (
        <List horizontal relaxed size={'big'}>
            <List.Item>
                Share:
        </List.Item>
            <List.Item as='a' href={"https://www.facebook.com/sharer/sharer.php?u=" + location} target='_blank'>
                <Icon size="1em" icon='facebook' />
            </List.Item>
            <List.Item as='a' href={"https://twitter.com/share?url=" + location} target='_blank'>
                <Icon size="1em" icon='twitter' />
            </List.Item>
            <List.Item as='a' href={"https://www.linkedin.com/shareArticle?mini=true&url=" + location} target='_blank'>
                <Icon size="1em" icon='linkedin' />
            </List.Item>
        </List>
    );
}

SocialSharing.propTypes = {
    location: PropTypes.string.isRequired,
};

export default SocialSharing