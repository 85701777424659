import React from 'react'
import {Button, Modal} from "semantic-ui-react";
import ModalComponent from "./ModalComponent";


const ModalFormComponent = (props) => {

    // console.log("[ModalFormComponent] render called " + props.isSubmitting)
    //Even though this code IS being rendered, there is no update happening on the screen for the modal (items in the background are changing)
    const isSubmitting = props.isSubmitting
    let buttonHtml = <Button onClick={props.accept} positive>Confirm</Button>
    if (isSubmitting){
        buttonHtml = <Button onClick={props.accept} positive loading disabled>Confirm</Button>
    }

    return(
        <ModalComponent displayModal={props.displayModal} close={props.close}  size={props.size} isSubmitting={isSubmitting} formSchema={props.formSchema}>
            <Modal.Header>{props.title}</Modal.Header>
            <Modal.Content>
                {props.children}
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={props.close} key="cancel">Cancel</Button>
                {buttonHtml}
            </Modal.Actions>
        </ModalComponent>

    )
};

export default ModalFormComponent;