import React from 'react';
import PropTypes from "prop-types";
import { Image } from 'semantic-ui-react';

const filestackImage = (props) =>  {

    let url = 'https://cdn.filestackcontent.com'

    let resizeProps = []
    if(props.height){
        resizeProps.push("height:"+props.height) 
    }
    if(props.width){
        resizeProps.push("width:"+props.width)
    }
    if(props.fit){
        resizeProps.push("fit:"+props.fit)
    }
    if(resizeProps.length>0){
        url = url +'/resize='+resizeProps.join(',')+'/pjpg=metadata:false,quality:100/'
    }
    let handle = props.handle;
    if(handle.indexOf("www.filepicker.io") !== -1){
        handle = handle.split("/");
        url = url + handle[handle.length - 1];
    }else {
        url = url + props.handle
    }
    return (
        <Image src={url} alt={props.alt} />
    )
}

filestackImage.propTypes = {
    handle: PropTypes.string.isRequired,
    height: PropTypes.number,
    width: PropTypes.number,
    fit: PropTypes.string
}


export default filestackImage;