import React from 'react'

const eventContext = React.createContext({
    attendanceResponse:null,
    //Event Information
    slug : "",
    gpsLocation : "",
    streetAddress : "",
    addressDetails : "",

    title : "",
    subtitle:"",
    leadImage:"",
    description:"",
    rsvpDate : "",
    eventDate : "",
    duration : 1,
    //Account details
    accountName : "",
    accountLogo : "",
    accountWebsite : "",
    contactEmailAddress:"",
    contactPhoneNumber:"",
    //form data
    initialValues : {},


//Form fields

    confirmAttendance: () => {},
    declineAttendance: () => {},
    clearAttendance: () => {}
});

export default eventContext