import React from 'react';
import PropTypes from 'prop-types';
import IcomoonReact from 'icomoon-react';
import iconSet from './selection.json';

const Icon = (props) => {
  const { color, size, icon } = props;
  return <IcomoonReact iconSet={iconSet} color={color} size={size} icon={icon}  className="ico-icon"/>;
};

Icon.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Icon.defaultProps = {
  color: '#444',
  size: '0.8em',
};

export default Icon;