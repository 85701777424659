import React from 'react';
import {Button, Modal} from 'semantic-ui-react'

export default function DateModal(props) {

    return (
        <Modal size='mini' open={props.isOpen} onClose={props.onRequestClose} className='calendar-modal'>
            <Modal.Header>Add to Calendar</Modal.Header>
            <Modal.Content>
                {props.children}
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={props.onRequestClose} fluid>Close</Button>
            </Modal.Actions>
        </Modal>
    );
}