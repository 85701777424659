import React  from 'react'
import { Image } from 'semantic-ui-react';

/**
 * Creates a GoogleMaps Static map
 * https://maps.google.com/maps/api/staticmap?markers=icon%3Ahttps%3A%2F%2Fsecure.meetupstatic.com%2Fs%2Fimg%2F5961591462445047%2Fmup-custom-google-map-pin.png%7C-34.098240%2C18.488140&zoom=17&size=480x300&sensor=false&key=AIzaSyA3fSxhHZNQNfMjJuMFvmULACXrBfFz9jQ
 */
const StaticMap  = (props) => {
    

    let googleMapLink = "https://maps.google.com/maps/api/staticmap?";
    let googleSearchLink = null;
    let latitude = null;
    let longitude = null;

    if (props.showMarker){
        googleMapLink +=  "markers=color:red%7C";
    }

    // console.log("[LocationMapComponent.js] props ", props);

    if (props.geoLocation !== undefined){

        if(props.linkToMap){
            googleSearchLink =  "https://www.google.com/maps/search/?api=1&query="+props.geoLocation;

        }

        let geoLocationArray = props.geoLocation.split(",")
        // console.log("geoLocationArray", geoLocationArray)
        if (geoLocationArray.length === 2){
            latitude = geoLocationArray[0];
            longitude = geoLocationArray[1];
        }
    }
    if (props.lat !== undefined && props.lng !== undefined){
        latitude = props.lat;
        longitude = props.lng;
    }

    if (process.env.REACT_APP_GOOGLE_MAP_KEY === undefined || process.env.REACT_APP_GOOGLE_MAP_SIGNATURE === undefined){
        throw new Error("Valid key and a valid map signature is required");
    }

    let locationHtml = <div>Loading map</div>
    if (latitude !== null && longitude !== null){
        googleMapLink +=
            latitude+"," +
            longitude+"&" +
            "zoom=17&size=480x300&" +
            "sensor=false&" +
            "key="+process.env.REACT_APP_GOOGLE_MAP_KEY;



        locationHtml = <Image src={googleMapLink} className='ui fluid image' alt='Location map' href={googleSearchLink} target="_blank" rel="nofollow"/>
    }
    return (locationHtml)

};

export default StaticMap;