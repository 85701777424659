import React from 'react';
import './App.scss';
import { Grid, Header} from 'semantic-ui-react'

const Landing = () => (
    <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
        <Grid.Column style={{ maxWidth: 450 }}>
            <Header as='h2' textAlign='center'>
                PropertyEngine RSVP
            </Header>
            <p>You've got here by altering the URL you where visiting as you probably wanting to find out what's powering this wonderful technology</p>
            <a href={process.env.REACT_APP_PROPERTYENGINE_ENDPOINT} className='ui primary button'>Click here to find out more</a>

        </Grid.Column>
    </Grid>
);


export default Landing;
