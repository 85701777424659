import React from 'react'
import {Item, Label} from 'semantic-ui-react'

const Badge = (props) =>{
    let website = props.website
    let websiteDisplay = website

    if(website && website.indexOf('http')>-1){
        websiteDisplay = website.replace('http://','').replace('https://','')
    }else{
        website = "http://"+website
    }

    return (
        <Item>
            {props.logo ?  <Item.Image size='tiny' src={props.logo} /> : null }
            <Item.Content>
                <Item.Header as='h4'>{props.title}</Item.Header>
                {props.email || props.website ? (
                <Item.Extra>
                    {props.website ?  <Label as='a' size='mini'  basic href={website} className='website' target='_blank' rel='nofollow'>{websiteDisplay}</Label> : null }
                    {props.email ?  <Label as='a' size='mini' basic href={'mailto:'+props.email} className='email' target='_blank' rel='nofollow'>{props.email}</Label> : null }
                    {props.phone ?  <Label as='a' size='mini'  basic href={'tel:'+props.phone} className='phone' rel='nofollow'>{props.phone}</Label> : null }
                </Item.Extra>
                ):null}
            </Item.Content>
        </Item>
    )
};

export default Badge