import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import './App.scss';
import { Grid, Container, Dimmer, Loader } from 'semantic-ui-react'
import Banner from '../components/Event/Banner/Banner'
import Flyer from '../components/Event/Flyer/Flyer'
import Information from '../components/Event/Information/Information'
import EventContext from '../context/event-context'
import ModalFormComponent from '../components/ModalComponent/ModalFormComponent'
import UserForm from "../components/UserForm/UserForm";
import cookie from 'react-cookies'
import Helmet from 'react-helmet'

class App extends Component {
    constructor(props, match) {
        super(props)
        // console.log(props)
        //Lets check localStorage
        //let localStorageUserData = localStorage.getItem('userData') || ''; Removed localStorage due to privacy concerns
        //The eventId should be fetched via the url
        this.state = {
            id: props.match.params.id,
            attendanceResponse: null,
            revisit: false,
            loadingState: true,
            isUserFeedbackOpen: false,
            isUserFormSubmitting: false,
            initialValues: {}
        }

    }

    userSubmitForm = null;

    userFormSubmitTrigger = (e) => {
        if (this.userSubmitForm) {
            // console.log("E?!@# ", e)
            this.bindSubmitting(true)
            this.userSubmitForm(e);
        }
    };

    bindSubmitForm = (submitForm) => {
        // console.log("bindSubmitForm foprm?!@#")
        this.userSubmitForm = submitForm;
    };

    bindSubmitting = (isSubmitting) => {
        // console.log("isSubmitting", isSubmitting)
        this.setState({ isUserFormSubmitting: isSubmitting })
    }

    /**
     * There are 3 events that will be triggered
     * 1) Page viewed (new, revisit)
     * 2) Declined
     * 3) Accepted + data
     */

    static getDerivedStateFromProps(props, state) {
        // console.log("[App.js] getDerivedStateFromProps", props, state);
        return state
    }

    //Handlers
    confirmAttendanceHandler = () => {
        this.setState({ isUserFeedbackOpen: true });
    }

    declineAttendanceHandler = () => {
        this.setState({ attendanceResponse: false });
        this.declinedAttendanceRequest();
    };

    clearAttendanceHandler = () => {
        this.setState({ attendanceResponse: null });
    };

    userFeedbackClosedHandler = () => {
        if (this.state.isUserFeedbackOpen) {
            this.setState({ isUserFeedbackOpen: false });
        }
    };

    formSubmitHandler = (data) => {
        return new Promise((resolve, reject) => {
            // console.log("form submit handler")
            data.event = this.state.id;
            data.type = 'attending';
            this.confirmedAttendanceRequest(data).then(() => {
                this.setState({ isUserFeedbackOpen: false });
                this.setState({ attendanceResponse: true });
                resolve()
                //reject(Error("Promise rejected"));
            })


        })


    };

    componentDidMount() {
        //Load data from cookie
        this.setState({
            revisit: this.convertStringToBoolean(cookie.load('event-view-' + this.state.id), false),
            attendanceResponse: this.convertStringToBoolean(cookie.load('event-response-' + this.state.id), null)
        });

        fetch(`${process.env.REACT_APP_API_ENDPOINT}/${process.env.REACT_APP_API_STAGE}/event/${this.state.id}`)
            .then(res => res.json())
            .then(
                (result) => {
                    // console.log("RESULT!", result)
                    //Set the initial values
                    if (result.result === "fail") {

                        this.setState({
                            isLoaded: true,
                            result
                        });
                    } else {
                        let initialValues = {}

                        let description = "";
                        description = result.description.split("\n").map((text, index) => {
                            return (
                                text && text.trim() && (
                                    <React.Fragment key={index}>
                                        {text}
                                        <br />
                                    </React.Fragment>
                                )
                            );
                        });
                        result.descriptionHtml = description;
                        result.formFields.forEach((field) => {
                            initialValues[field.code] = field.defaultValue || ""

                        });

                        // console.log("initialValues",  initialValues);
                        result['initialValues'] = initialValues;
                        result['loadingState'] = false;
                        this.setState({
                            ...result
                        });
                        this.viewedFormRequest();
                    }
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                    // console.log("ERRO!", error)
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )

        //we need to trigger view
    }

    viewedFormRequest() {
        //{{endpoint}}/{{stage}}/msg/send?MessageBody={ "event":"{{eventPk}}","type":"view"}
        // console.log("[App.js] viewedFormRequest")
        let messageBody = encodeURIComponent(JSON.stringify({ "event": this.state.id, "type": this.state.revisit ? "view" : "first-view" }));
        //messageBody = '"'+JSON.stringify(messageBody)+'"';
        const requestUrl = `${process.env.REACT_APP_API_ENDPOINT}/${process.env.REACT_APP_API_STAGE}/msg/send?MessageBody=${messageBody}`;

        // console.log("[App.js] viewedFormRequest--requestUrl ", requestUrl)
        // console.log("[App.js] viewedFormRequest--messageBody ", messageBody)
        // console.log("[App.js] cookie.load('event-view') ", cookie.load('event-view-'+this.state.eventId))
        fetch(requestUrl, {})
            .then(res => res.json()
                .then(
                    (result) => {
                        // console.log(result);
                        //store cookie for this PK
                        cookie.save('event-view-' + this.state.id, true, { path: '/' })
                    },
                    (error) => {
                        this.setState({
                            isLoaded: true,
                            error
                        });
                    })
            )
    }

    confirmedAttendanceRequest(data) {
        return new Promise((resolve, reject) => {
            let messageBody = encodeURIComponent(JSON.stringify(data));
            //throw Error("Broken..")
            const requestUrl = `${process.env.REACT_APP_API_ENDPOINT}/${process.env.REACT_APP_API_STAGE}/msg/send?MessageBody=${messageBody}`;
            fetch(requestUrl, {})
                .then(res => res.json()
                    .then(
                        (result) => {
                            // console.log(result);
                            cookie.save('event-response-' + this.state.id, true, { path: '/' });
                            this.setState({
                                initialValues: data
                            })
                            //store cookie for this PK
                            resolve()
                        },
                        (error) => {
                            this.setState({
                                isLoaded: true,
                                error
                            });
                            reject(error)
                        })
                )
        });
    }

    declinedAttendanceRequest() {
        let alreadyResponded = this.convertStringToBoolean(cookie.load('event-declined-' + this.state.id), false);
        if (!alreadyResponded) {
            let messageBody = encodeURIComponent(JSON.stringify({ "event": this.state.id, "type": "decline" }));
            const requestUrl = `${process.env.REACT_APP_API_ENDPOINT}/${process.env.REACT_APP_API_STAGE}/msg/send?MessageBody=${messageBody}`;
            fetch(requestUrl, {})
                .then(res => res.json()
                    .then(
                        (result) => {
                            // console.log(result);
                            //store cookie for this PK
                            cookie.save('event-response-' + this.state.id, false, { path: '/' });
                            cookie.save('event-declined-' + this.state.id, true, { path: '/' })
                        },
                        (error) => {
                            this.setState({
                                isLoaded: true,
                                error
                            });
                        })
                )
        } else {
            // console.log("[App.js] -- declinedAttendanceRequest -- Already declined response")
        }

    }

    convertStringToBoolean(stringValue, defaultValue) {
        // console.log("[convertStringToBoolean]", stringValue)
        if (stringValue === undefined) {
            return defaultValue
        } else {
            return JSON.parse(stringValue)
        }
    }


    render() {
        let stateObject = { ...this.state };
        stateObject.confirmAttendance = this.confirmAttendanceHandler;
        stateObject.declineAttendance = this.declineAttendanceHandler;
        stateObject.clearAttendance = this.clearAttendanceHandler;
        // console.log("stateObject === ", stateObject)
        return (

            <div className="PropertyEngineRSVP">
                {this.state.loadingState && this.state.isLoaded ? <Redirect to="/" /> : null}
                <Dimmer active={this.state.loadingState}>
                    <Loader />
                </Dimmer>
                <EventContext.Provider value={{ ...stateObject }}>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>{stateObject.title}</title>
                        <meta name="description" content={stateObject.description} />
                        <link rel="canonical" href={'https://netlify-rsvp.propertyengine.com/' + stateObject.id} />

                        <meta name="title" content={stateObject.title} />
                        <meta name="description" content={stateObject.description} />

                        <meta property="og:type" content="website" />
                        <meta property="og:url" content={'https://netlify-rsvp.propertyengine.com/' + stateObject.id} />
                        <meta property="og:title" content={stateObject.title} />
                        <meta property="og:description" content={stateObject.description} />
                        <meta property="og:image" content={stateObject.leadImage} />

                        <meta property="twitter:card" content="summary_large_image" />
                        <meta property="twitter:url" content={'https://netlify-rsvp.propertyengine.com/' + stateObject.id} />
                        <meta property="twitter:title" content={stateObject.title} />
                        <meta property="twitter:description" content={stateObject.description} />
                        <meta property="twitter:image" content={stateObject.leadImage} />


                    </Helmet>
                    <section className='section--banner'>
                        <Container >
                            <Banner />
                        </Container>
                    </section>
                    <section className='section--content'>
                        <Container>
                            <Grid columns={2} stackable className='main-content'>
                                <Grid.Column width={10}>
                                    <Flyer />
                                </Grid.Column>
                                <Grid.Column width={6}>
                                    <Information />
                                </Grid.Column>
                            </Grid>
                        </Container>
                    </section>

                    <ModalFormComponent title="Confirm details" size='tiny'
                        displayModal={this.state.isUserFeedbackOpen}
                        close={this.userFeedbackClosedHandler}
                        accept={this.userFormSubmitTrigger}
                        isSubmitting={this.state.isUserFormSubmitting}
                        formSchema={stateObject.formFields}
                    >
                        <UserForm bindSubmitForm={this.bindSubmitForm}
                            bindSubmitting={this.bindSubmitting}
                            intialValues={stateObject.initialValues}
                            formSchema={stateObject.formFields}
                            onSubmit={(data) => this.formSubmitHandler(data)} />
                    </ModalFormComponent>
                    {/*<DisplayFormikState {...this.state} />*/}
                </EventContext.Provider>
            </div>
        );
    }

}

export default App;
