import React from 'react';
import './App.scss';
import {Grid, Header} from "semantic-ui-react";

const Info = (props) => {

    let apiEndpoint =  process.env.REACT_APP_API_ENDPOINT;
    let apiStage = process.env.REACT_APP_API_STAGE;
    let mapKey = process.env.REACT_APP_GOOGLE_MAP_KEY;
    return (

        <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
            <Grid.Column style={{ maxWidth: 450 }}>
                <Header as='h2' textAlign='center'>
                    PropertyEngine RSVP
                </Header>
                <p>Map Key: {mapKey}</p>
                <p>API Stage: {apiStage}</p>
                <p>API Endpoint: {apiEndpoint}</p>
            </Grid.Column>
        </Grid>
    );
};


export default Info;
