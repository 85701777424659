import React, { useContext } from 'react'
import { Header} from 'semantic-ui-react'
import EventContext from "../../../context/event-context";
import FSImage from '../../Utils/FilestackImage'

const Flyer  = (props) => {

    const context = useContext(EventContext);

    let imageHtml = "";
    let descriptionHtml = "";

    if (context.leadImage){
        imageHtml = <div className='flyer__image'>
            <FSImage handle={context.leadImage} width={1400} fit={"scale"}/>
        </div>
    }

    if (context.description){
        descriptionHtml =  <div className='flyer__description'>
            <Header as='h2'>Details</Header>
            <p>{context.descriptionHtml}</p>
        </div>
    }

    return(
        <div className='flyer'>
            {imageHtml}
            {descriptionHtml}
        </div>
    )


};

export default Flyer;