import React from "react";
import Info from './Info';
import Landing from './Landing';
import App from './App';
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";

function RsvpRouter() {
    return (
        <Router>
            <div>
                <Switch>
                    <Route path="/" exact component={Landing} />
                    <Route path="/info/summary" exact component={Info} />
                    <Route path="/:id" component={App} />
                    <Route render={() => <Redirect to={{pathname: "/"}} />} />
                </Switch>
            </div>
        </Router>
    );
}
export default RsvpRouter;
