import React, { Component } from 'react';
import { Button, Segment } from 'semantic-ui-react';
import Icon from "../../Icon/Icon"
import EventContext from "../../../context/event-context";
import SocialSharing from '../../SocialSharing/SocialSharing';

class UserResponse extends Component {

    constructor(props) {
        super(props);
        this.buttonReset = React.createRef();
    }

    static contextType = EventContext;
    render() {
        let userResponseHtml = (
            <div className='user-response'>
                <Button className='ui huge positive button' ref={this.buttonConfirm} onClick={this.context.confirmAttendance}>
                    <Icon color="#fff" size="1em" icon='check' /> Yes
                </Button>
                <Button className='ui huge button' onClick={this.context.declineAttendance}>
                    <Icon size="1em" icon='cross' />
                    No
                </Button>
                <SocialSharing location={window.location.href}/>
            </div >);
        if (this.context.attendanceResponse !== null) {
            userResponseHtml = (<div className='user-response'>
                {<Segment color={this.context.attendanceResponse ? 'blue' : 'red'}>
                    <Button className="text-button" floated='right' size='tiny' basic ref={this.buttonReset} onClick={this.context.clearAttendance}>Change</Button>
                    {this.context.attendanceResponse ? "You have said you are coming" : "You are not attending"}
                </Segment>}
            </div>)
        }
        return (
            userResponseHtml
        )
    }

}

export default UserResponse;
