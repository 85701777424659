import React, {Component} from 'react'
import {Modal} from 'semantic-ui-react'

class ModalComponent extends Component {

    // TODO: @Corne Shouldn't this be set with a constructor versus having to hand code each this.props
    state = {open:this.props.displayModal, size:this.props.size};
    show = size => () => this.setState({ size, open: true });

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        // console.log('[ModalComponent.js] shouldComponentUpdate');
        //this allows us to determine if we should render the persons list or not
        return true
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log('[ModalComponent] componentDidUpdate')
        // console.log(prevProps)
        // console.log(prevState)
        // console.log(snapshot)
    }

    componentWillReceiveProps(nextProps){
        // console.log("[ModalComponent] componentWillReceiveProps", nextProps)
        this.setState({open:nextProps.displayModal})
    }

    render(){
        // console.log("[ModalComponent] state ", this.state)
        const { open, size } = this.state

        return(
            <Modal size={size} open={open} onClose={this.props.close}>
                {this.props.children}
            </Modal>
        )
    }
}

export default ModalComponent;